import React from 'react';
import '../../App.css';

export default function Preservation() {
  return (
    <>
      <section className="containerBox">
        <div className="rowContainer">
          <div className="row">
            <div className="col50">
              <div className="imgBx">
                <img src="/images/box.jpg" alt="box" />
              </div>
            </div>
            <div className="col50">
              <div className="text">
                <h2 className="titleText">
                  <span>P</span>reservation
                </h2>
                <p>
                  Proper preservation involves things I do, and things you can do, to insure that
                  this transformation is successful and your christening gown is preserved for
                  future generations.
                  <br />
                  Textiles should always be stored flat, in an acid free environment. Off gasses
                  from the chemicals to make paper and plastics are what turn fabric yellow and
                  damage threads. I provide a storage box which allows the gown to be laid flat.
                  This box is the same type museums use to store textiles. It is acid and buffer
                  free, has reinforced metal edges, and also includes acid free tissue paper for
                  packing.
                  <br />
                  Your part in the preservation process is to store the archive box in a cool dry
                  place.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
