import React from 'react';
import '../pages/Portfolio.css';

const Portfolio = () => {
  return (
    <div>
      <section className="gallery">
        <div className="title">
          <h2 className="titleText">
            <span>M</span>y Portfolio
          </h2>
        </div>
        <div className="container">
          <div className="box">
            <img src="/images/portfolio/p1.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p2.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p3.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p4.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p5.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p6.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p7.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p8.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p9.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p10.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p11.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p12.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p14.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p15.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p16.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p17.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p18.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p19.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p20.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p21.jpg" alt="Portfolio" />
          </div>
          <div className="box">
            <img src="/images/portfolio/p22.jpg" alt="Portfolio" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
