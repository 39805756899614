import React from 'react';
import '../../App.css';

export default function About() {
  return (
    <>
      <section className="containerBox">
        <div className="rowContainer">
          <div className="row">
            <div className="col50">
              <div className="text">
                <h2 className="titleText">
                  <span>A</span>bout Us
                </h2>
                <p>
                  Your heirloom quality <span>Kingdom Gown</span> will be carefully designed and
                  sewn to the highest couture standard to be treasured and appreciated by future
                  generations. Your <span>Kingdom Gown</span> is created using the fabric and
                  trimmings from your wedding gown to be soft, durable, and truly unique. Instead of
                  languishing in a box on the top shelf of a closet, the wonderful gown you wore on
                  your wedding day is transformed; it will be a continuing and beautiful statement
                  of love and your commitment to the future.
                  <br />A <span>Kingdom Gown</span> is french seamed (see couture details), and all
                  trims, pearls and embellishments are individually attached. Each gown comes with a
                  slip, and matching bonnet and booties. Also included is a museum quality archive
                  box, (see Preservation), acid free tissue paper, a history book, and cleaning and
                  storage instructions.
                </p>
              </div>
            </div>
            <div className="col50">
              <div className="imgBx">
                <img src="/images/24.png" alt="pic1" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
