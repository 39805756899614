import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../pages/Testimonials.css';

class Testimonials extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <h2 className="titleText">
            <span>T</span>estimonials
          </h2>
          <div id="testimonials">
            <div className="containerTestimonial">
              <OwlCarousel items={1} className="owl-theme" loop nav margin={8}>
                <div className="testimonials-box item">
                  <div className="testimonial-photo">
                    <img src="/images/logo2.png" alt="" />
                  </div>
                  <h3>
                    Mann Family
                    <br />
                    Brentwood, California
                  </h3>
                  <p>
                    "The dress was just so soft. Ericka seemed very comfortable in it. I've never
                    experienced this level of service on a custom order. Thank you from the Mann
                    family."
                  </p>
                </div>
                <div className="testimonials-box item">
                  <div className="testimonial-photo">
                    <img src="/images/logo2.png" alt="" />
                  </div>
                  <h3>
                    Liz Perry
                    <br />
                    Grand Rapids, Michigan
                  </h3>
                  <p>
                    "Wow! Dress was just exquisite. I was worried with all those pearls but not one
                    came off. Love to you and your family."
                  </p>
                </div>
                <div className="testimonials-box item">
                  <div className="testimonial-photo">
                    <img src="/images/logo2.png" alt="" />
                  </div>
                  <h3>
                    C. Davis
                    <br />
                    Sag Harbour, New York
                  </h3>
                  <p>
                    "Just a note to thank you for the beautiful dress. It made the day! Everyone
                    ohood and ahaad over it. Cathleen will cherish it for all her children."
                  </p>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials;
