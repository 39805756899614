import React, { useState, useEffect } from 'react';
import './Phone.css';

function Navbar() {
  return (
    <>
      <div className="phone">
        <a href="tel:304-620-2250">
          <div className="phone-icon">
            <span className="icons">
              <i className="fas fa-phone-alt"></i>
            </span>
          </div>
          <div className="circle1"></div>
          <div className="circle2"></div>
        </a>
      </div>
    </>
  );
}

export default Navbar;
