import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer">
      <div id="contact">
        <div className="footer_info">
          <div className="footer_width about">
            <h2>About us</h2>
            <p>
              Your heirloom quality Kingdom Gown will be carefully designed and sewn to the highest
              couture standard to be treasured and appreciated by future generations. Your Kingdom
              Gown is created using the fabric and trimmings from your wedding gown to be soft,
              durable, and truly unique. Instead of languishing in a box on the top shelf of a
              closet, the wonderful gown you wore on your wedding day is transformed; it will be a
              continuing and beautiful statement of love and your commitment to the future.
              <br />
              Established in Grand Rapids, Michigan in 2008 and is proud to be an American company.
            </p>
          </div>
          <div className="footer_width link">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <Link to="/about" className="slide-section">
                  About
                </Link>
              </li>
              <li>
                <Link to="/couture-details" className="slide-section">
                  Couture Details
                </Link>
              </li>
              <li>
                <Link to="/preservation" className="slide-section">
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/portfolio" className="slide-section">
                  Portfolio
                </Link>
              </li>
              <li>
                <Link to="/testimonials" className="slide-section">
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer_width contact">
            <h2>Contact</h2>
            <ul>
              <li>
                <span>
                  <i className="fas fa-map-marked-alt"></i>
                </span>
                <p>3249-D Huntington Wood Drive SE, Kentwood, Michigan 49512</p>
              </li>
              <li>
                <span>
                  <i className="far fa-envelope"></i>
                </span>
                <Link to="/contact-form" className="slide-section">
                  Contact Me!
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="copy-right">
          <p>
            © KINGDOM GOWNS. ALL RIGHTS RESERVED {new Date().getFullYear()}. WEBSITE BUILT BY ARSLAN
            ABAKAROV
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
