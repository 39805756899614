import React from 'react';
import '../App.css';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className="banner">
      <div className="bannerImg">
        <img src="/images/castle.jpg" alt="" />
      </div>
      <div className="content">
        <h2>Welcome to the</h2>
        <img className="imgLogoBanner" src={'/images/logo.png'} alt="" />
      </div>
    </div>
  );
}

export default HeroSection;
