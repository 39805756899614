import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from './components/pages/About';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Phone from './components/Phone';
import CoutureDetails from './components/pages/CoutureDetails';
import Preservation from './components/pages/Preservation';
import Portfolio from './components/pages/Portfolio';
import Testimonials from './components/pages/Testimonials';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <Navbar />
          <Phone />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/couture-details" component={CoutureDetails} />
            <Route path="/preservation" component={Preservation} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/testimonials" component={Testimonials} />
            <Route path="/contact-form" component={Contact} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
