import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className="cards">
      <h1 className="titleText">
        <span>K</span>ingdom <span>G</span>owns Couture Details
      </h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/IMG_2520.jpeg"
              text="All Christening gowns are constructed using the finest in French hand sewing techniques."
              label="Highest couture standard"
            />
            <CardItem
              src="images/IMG_2904.jpeg"
              text="A French seam is an elegant way of enclosing the raw edge of the fabric so no rough edges
              touches the baby's delicate skin. I use a french whipped seam to connect delicate fabrics or
              laces."
              label="Your beautiful statement of love"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/34.jpg"
              text="Most gowns are constructed using a combination running stitch and a backstitch."
              label="Carefully designed and handsewn"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
